<template>
  <div class="my-6">
    <h3 class="font-bold">Detalhes da carga</h3>
    <span class="text-sm text-gray-500">
      Segue detalhes da carga realizada as
      {{ carga && carga["data_inicio"] ? new Date(carga["data_inicio"]).toLocaleString('pt-BR', {
        dateStyle: 'short',
        timeStyle: 'medium'
      }) : 'Data não disponível' }} que está com status
      {{ carga && carga["status"] ? carga["status"] : 'Status não disponível' }}
    </span>
  </div>
  <div class="mt-4 card shadow-sm border p-8 bg-white  overflow-x-auto">
    <DataTable :headers="headers" :data="[...data]" :options="options" color="primary" />
  </div>
</template>

<script>
import { GET, PUT, POST } from "../../services/api";
import { inject, onMounted, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import DataTable from "../../components/Elements/Datatable.vue";


export default {
  name: 'DetalhesCarga',
  components: {
    DataTable,
  },

  setup() {
    const route = useRoute();
    const loader = inject("loading");
    const alert = inject("alert");
    const pagina = ref(0)
    const data = ref([]);

    const carga = ref();
    const modal = inject("modalLogs");

    let intervalCarga;
 

    async function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }



 

    async function getCarga() {
      try {
        const response = await GET(`gerenciar-lotes/${route.params.id}`);

        if (response.success) {
          data.value = response.data.filaProcesso.filter(item=> item.status === 'erro').map((item => {
            return {
              id: item.id,
              status: item.status,
              content: JSON.stringify(item.content),
              error: JSON.stringify(item.error),
              "updated_at": new Date(item.updated_at).toLocaleString('pt-BR'),
            };
          }));

          carga.value = response.data


        }


      } catch (error) {
        console.error("Erro ao obter dados da carga:", error);
        throw error;
      }
    }

    onMounted(async () => {
      try {
        delay(400).then(() => {
          loader.open();
        })
        await getCarga()
 
        delay(400).then(() => {
          loader.close();
        })

        intervalCarga = setInterval(async () => {
          await getCarga();
        }, 10000);

        
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
        delay(400).then(() => {
          loader.close();
        })
      }
    })



    async function refazerProcesso(param) {
      try {
        if (param === undefined) {
          alert.open("Atenção!", "Selecione um processo para refazer", "warning");
          return
        }

        const response = await POST(`gerenciar-lotes/reprocessar_itens`, [
          {
            "id_evento": param.id_evento,
            "id_processo": param.id
          }
        ])

        if (response.success) {
          alert.open("Sucesso!", response.msg, "success")
        }
        await getCarga()


      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }


    async function visualizarErro(param) {
      try {
        if (!param) {
          alert.open("Atenção!", "Selecione um processo para visualizar", "warning");
          return
        }

        if (!param.error) {
          alert.open("Atenção!", "Não existe erro para ser visualizado", "warning");
          return
        }



        modal.open(JSON.stringify(param.error), '');

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }

    async function visualizarConteudo(param) {
      try {
        if (!param) {
          alert.open("Atenção!", "Selecione um processo para visualizar", "warning");
          return
        }

        if (!param.content) {
          alert.open("Atenção!", "Não existe conteudo para ser visualizado", "warning");
          return
        }

        modal.open(JSON.stringify(param.content), '');
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }


    const headers = [
      { key: "Id", value: "id" },
      
      { key: "Conteúdo", value: "content", limit: 50 },
      { key: "Error", value: "error", limit: 50 },
      { key: "Realizado", value: "updated_at" },
      { key: "Ações", value: "" },
    ];

    const options = [
      { icon: "rotate-right", action: (param) => refazerProcesso(param), title: "Refazer" },
      { icon: "eye", action: (param) => visualizarErro(param), title: "Visualizar Erro" },

    ];

    


    const cleanupInterval = () => {
      clearInterval(intervalCarga);
  
    };

    onUnmounted(cleanupInterval);


    const setPagina = (pag) => {
      pagina.value = pag

    }
    return {
      headers,
      options,
      data,
      pagina,
      setPagina,

      carga
    }
  }
};
</script>

<style scoped>
.detalhes-carga {
  padding: 20px;
}
</style>